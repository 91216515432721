<template>
  <div>
    <div class="d-flex justify-content-start">
      <span><strong>Alteração em porcentagem por categoria</strong></span>
    </div>
    <div class="d-flex justify-content-start row">
      <div class="col-sm-8">
        <div class="d-flex justify-content-start mt-2 mb-2">
          <select
            class="form-control mr-1 col-4"
            name="select"
            id="select"
            required="required"
          >
            <option value="" :selected="!selected || selected == 0">
              Selecione
            </option>
            <option
              v-for="(option, index) in options"
              :key="index"
              :value="option.value"
              :selected="selected == option.value || false"
            >
              {{ option.label }}
            </option>
          </select>
          <input
            type="number"
            placeholder="%"
            class="form-control mr-2 col-2"
            v-model="percentage"
            min="0"
          />
          <button
            type="button"
            class="btn btn-primary btn-flat mr-1 col-auto"
            v-on:click="Sum()"
          >
            <i class="fa fa-plus"></i>
          </button>
          <button
            type="button"
            class="btn btn-primary btn-flat col-auto"
            v-on:click="Sub()"
          >
            <i class="fa fa-minus"></i>
          </button>
        </div>
      </div>
    </div>

    <div
      class="d-flex col-12 align-items-center no-padding-right no-padding-left"
    >
      <span class="col-sm-3 tag">Nome</span>
      <span class="col-sm-2 tag">Categoria</span>
      <span class="col-sm-2 tag">Tamanho</span>
      <span class="col-sm-3 tag">Imagem</span>
      <span class="col-sm-2 tag">Valor</span>
    </div>

    <span v-for="(item, index) in items" :key="index">
      <div
        class="d-flex col-12 align-items-center tag-items-master"
        :style="index % 2 == 0 ? 'background-color: rgba(0, 0, 0, 0.05);' : ''"
      >
        <span class="col-sm-3 tag-items">{{ item.name }}</span>
        <span class="col-sm-2 tag-items">{{ item.category_name }}</span>
        <div class="col-sm-2 tag-items">{{ item.size }}</div>
          <img
            class="col-sm-3 ml-sm-auto tag-items"
            :src="item.image"
            style="max-width: 150px; max-height: 150px"
        />
        <money
          class="form-control col-sm-2 ml-sm-auto tag-items"
          v-model="item.price"
          v-bind="money"
          v-on:blur.native="Check(item, index)"
        ></money>
      </div>
    </span>
    <pagination
      class="mt-2"
      :data="laravelData"
      @pagination-change-page="getResults"
    ></pagination>
    <button
      type="button"
      class="btn btn-success btn-flat ctaButton mt-2"
      v-on:click="Save()"
    >
      Salvar
    </button>
    <button
      type="button"
      class="btn btn-default btn-flat ctaButton mt-2"
      onclick="location.href='/cms/shop_menu/shop_services';"
    >
      Cancelar
    </button>
  </div>
</template>
<style scoped="sass">
.tag {
  padding: 0.75rem;
  border: 1px solid #f4f4f4;
  border-bottom-width: 2px;
  vertical-align: bottom;
}
.tag-items-master {
  padding: 0.75rem;
  border: 1px solid #f4f4f4;
}
.tag-items {
  vertical-align: middle !important;
}
</style>
<script>
import { Money } from "v-money";
import axios from "axios";

export default {
  components: { Money },
  data() {
    return {
      laravelData: {},
      price: this.value,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false,
      },
      list: [],
      items: [],
      selected: null,
      options: [],
      percentage: null,
    };
  },

  created() {
    // Fetch initial results
    this.getResults();
  },

  methods: {
    getResults(page = 1) {
      axios.get("/api/pagination/results?page=" + page).then((response) => {
        this.laravelData = response.data;
        this.items = response.data.data;
        this.SelectList();
      });
    },

    SelectList() {
      for (let i = 0; i < this.laravelData.data.length; i++) {
        let filter_options = this.options.filter(
          (options) => options.label == this.laravelData.data[i].category_name
        );
        if (filter_options.length == 0) {
          this.options.push({
            label: this.laravelData.data[i].category_name,
            value: this.laravelData.data[i].category_id,
          });
        }
      }
    },

    Check(item, index) {
      let filter_list = this.list.filter(
        (list) => list.size_id == item.size_id
      );
      if (filter_list.length == 0) {
        this.list.push(item);
      }
    },

    CheckPercentage(percentage_list) {
      for (let i = 0; i < percentage_list.length; i++) {
        let filter_list = this.list.filter(
          (list) => list.size_id == percentage_list[i].size_id
        );
        if (filter_list.length == 0) {
          this.list.push(percentage_list[i]);
        }
      }
    },

    Sum() {
      let percentage_list = [];
      let val = document.getElementById("select");
      let val_value = val.options[val.selectedIndex].value;
      let val_label = val.options[val.selectedIndex].text;
      if (
        val_value == "" ||
        val_label == "Selecione" ||
        !this.percentage ||
        this.percentage <= 0
      ) {
        Toast.fire("Por favor, preencha os campos", "", "error");
        return false;
      }

      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].category_id == val_value) {
          this.items[i].price =
            (this.percentage / 100) * this.items[i].price + this.items[i].price;
          percentage_list.push(this.items[i]);
        }
      }
      Toast.fire(
        "Somado " +
          "&nbsp<strong>" +
          this.percentage +
          "%" +
          "</strong>&nbsp" +
          "a categoria:&nbsp" +
          "<strong>" +
          val_label +
          "</strong>",
        "",
        "success"
      );
      this.CheckPercentage(percentage_list);
    },

    Sub() {
      let percentage_list = [];
      let val = document.getElementById("select");
      let val_value = val.options[val.selectedIndex].value;
      let val_label = val.options[val.selectedIndex].text;
      if (
        val_value == "" ||
        val_label == "Selecione" ||
        !this.percentage ||
        this.percentage <= 0
      ) {
        Toast.fire("Por favor, preencha os campos", "", "error");
        return false;
      }
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].category_id == val_value) {
          this.items[i].price =
            this.items[i].price - (this.percentage / 100) * this.items[i].price;
          percentage_list.push(this.items[i]);
        }
      }

      Toast.fire(
        "Subtraido " +
          "&nbsp<strong>" +
          this.percentage +
          "%" +
          "</strong>&nbsp" +
          "da categoria:&nbsp" +
          "<strong>" +
          val_label +
          "</strong>",
        "",
        "success"
      );

      this.CheckPercentage(percentage_list);
    },

    Save() {
      Toast.fire("Dados salvos com sucesso", "", "success");
      if (this.list.length == 0) {
        return false;
      } else {
        this.SendToApi();
      }
    },

    SendToApi() {
      axios
        .post("/api/alter_lot", this.list)
        .then((response) => {
          // console.log(response.data);
        })
        .catch(function (error) {
          // console.log(error.response);
        });
    },
  },
};
</script> 