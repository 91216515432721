<template>
  <div>
    <div class="d-flex row justify-content-around form-group">
      <label
        class="col-xl-2 col-lg-2 col-sm-2 col-12 text-lg-right text-sm-left"
        >Cores*</label
      >
      <div class="col-xl-2 col-lg-2 col-sm-2 col-4 mb-sm-0 mb-2">
        <input
          v-model="selected_color"
          class="ColorPicker"
          type="color"
          v-on:change="SelectColor()"
        />
      </div>

      <div class="col-xl-8 col-lg-8 col-sm-8 col-8">
        <fieldset class="d-flex d-row" style="flex-wrap: wrap; gap: 0.5rem">
          <div
            v-for="(color, index) in color_list"
            :key="index"
            class="ColorList d-flex justify-content-center"
            type="color"
            :style="'background-color:' + color"
            v-on:click="Remove(index)"
          >
            <i class="fa fa-remove align-self-center" style="display: none"></i>
          </div>
        </fieldset>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.ColorList {
  border-radius: 10px;
  height: 2em;
  width: 2em;
  border: 1px solid #444;
  padding: 1px;
  margin-right: 5px;
  &:last-child {
    margin-right: 0px;
  }
}

.ColorList:hover {
  transform: scale(1.1);
  cursor: pointer;
  i {
    transform: scale(1.1);
    display: inline !important;
  }
}

.ColorPicker {
  height: 100%;
  width: 100%;
}
</style>
<script>
export default {
  data() {
    return {
      color_list: [],
      selected_color: "",
    };
  },

  mounted(){
    if (document.getElementById("colors").value){
      this.color_list = document.getElementById("colors").value.split(',');
    }
  },

  methods: {
    SelectColor() {
      if (this.color_list.includes(this.selected_color) == true) {
        Toast.fire("Esta cor já foi cadastrada!", "", "error");
        return false;
      } else {
        Toast.fire("Nova cor cadastrada!", "", "success");
        this.color_list.push(this.selected_color);
        document.getElementById("colors").value = this.color_list;
      }
    },

    Remove(index) {
      this.color_list.splice(index, 1);
      document.getElementById("colors").value = this.color_list;
    },
  },
};
</script> 