/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

window.Popper = require('popper.js');

window.$ = window.jQuery = require('jquery');

require('admin-lte');
require("bootstrap");

window.Vue = require("vue");

import Vue from "vue";
import VueSweetalert2 from 'vue-sweetalert2';
import Swal from "sweetalert2/dist/sweetalert2.js";
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  timer: 7500,
  showConfirmButton: false
});
window.Toast = Toast;

import money from "v-money";
Vue.use(money, { precision: 4 });

import VueTheMask from 'vue-the-mask';
Vue.use(VueTheMask);

import vClickOutside from 'v-click-outside'
Vue.use(vClickOutside)



/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
Vue.component('pagination', require('laravel-vue-pagination')).default;
Vue.component("content-header", require("./cms/ContentHeader.vue").default);
Vue.component("data-table", require("./cms/DataTable.vue").default);
Vue.component("data-table-shop", require("./cms/DataTableShop.vue").default);
Vue.component("ui-size", require("./cms/UISize.vue").default);
Vue.component("tabs", require("./cms/Tabs.vue").default);
Vue.component("ui-form", require("./cms/UIForm.vue").default);
Vue.component("ui-cart-status", require("./cms/UICartStatus.vue").default);
Vue.component("ui-form-cart", require("./cms/UIFormCart.vue").default);
Vue.component("ui-select", require("./cms/UISelect.vue").default);
Vue.component("ui-alteration", require("./cms/UIAlteration.vue").default);
Vue.component("ui-discount", require("./cms/UIDiscount.vue").default);
Vue.component("ui-colors", require("./cms/UIColors.vue").default);
Vue.component("ui-textarea", require("./cms/UITextarea.vue").default);
Vue.component("ui-money", require("./cms/UIMoney.vue").default);
Vue.component("ui-parcel", require("./cms/UIParcel.vue").default);
Vue.component("ui-delivery", require("./cms/UIDelivery.vue").default);
Vue.component("ui-coupons-uses", require("./cms/UICouponUses.vue").default);
Vue.component("ui-percent", require("./cms/UIPercent.vue").default);
Vue.component("ui-tracking", require("./cms/UITracking.vue").default);
Vue.component("ui-phone", require("./cms/UIPhone.vue").default);
Vue.component("ui-mask-input", require("./cms/UIMaskInput.vue").default);
Vue.component("alert", require("./cms/Alert.vue").default);
Vue.component("checkboxes", require("./cms/Checkboxes.vue").default);
Vue.component("radios", require("./cms/Radios.vue").default);
Vue.component("dropdown-list", require("./cms/DropdownList.vue").default);
Vue.component("dropdown-events", require("./cms/DropdownEvents.vue").default);
Vue.component("cidade-bairro", require("./cms/Cidade-bairro.vue").default);
Vue.component('file-upload', require('./cms/FileUpload.vue').default);
Vue.component('file-upload-shop', require('./cms/FileUploadShop.vue').default);

Vue.component("the-gallery", require("./front/TheGallery.vue").default);
Vue.component("main-slider", require("./front/MainSlider.vue").default);
Vue.component("signup", require("./front/SignUp.vue").default);

const app = new Vue({
  el: "#app"
});

flatpickr('#startDate', {
  enableTime: true,
  minDate: "today",
  dateFormat: "d/m/Y",
  enableTime: false,
  disableMobile: true,
  "plugins": [new rangePlugin({ input: "#endDate"})]
});

flatpickr('#inputDate', {
  enableTime: true,
  altInput: true,
  minDate: "today",
  dateFormat: "Y-m-d H:i",
  altFormat: "d/m/Y H:i",
  time_24hr: true,
  disableMobile: true
});

tinymce.init({
  selector: 'textarea#full_textarea_disable',
  language: 'pt_BR',
  plugins: ['image code','media'],
  toolbar: 'undo redo styleselect bold italic link image media alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',  /* enable title field in the Image dialog*/
  height : "420",
  readonly : 1,
  image_title: true,
  automatic_uploads: true,

  file_picker_types: 'image',
  /* and here's our custom image picker*/
  file_picker_callback: function (cb, value, meta) {
    var input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');



    input.onchange = function () {
      var file = this.files[0];

      var reader = new FileReader();
      reader.onload = function () {

        var id = 'blobid' + (new Date()).getTime();
        var blobCache =  tinymce.activeEditor.editorUpload.blobCache;
        var base64 = reader.result.split(',')[1];
        var blobInfo = blobCache.create(id, file, base64);
        blobCache.add(blobInfo);

        cb(blobInfo.blobUri(), { title: file.name });
      };
      reader.readAsDataURL(file);
    };

    input.click();
  },
  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
});

  tinymce.init({
      selector: "textarea#full_textarea",
      language: 'pt_BR',
      relative_urls: false,
      paste_data_images: true,
      image_title: true,
      automatic_uploads: true,
      images_upload_url: "/api/upload",
      file_picker_types: "image",
      height : "420",
      plugins: [
          "advlist autolink lists link image charmap print preview hr anchor pagebreak",
          "searchreplace wordcount visualblocks visualchars code fullscreen",
          "insertdatetime media nonbreaking save table contextmenu directionality",
          "emoticons template paste textcolor colorpicker textpattern"
      ],
      toolbar1:
          "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
      toolbar2: "print preview media | forecolor backcolor emoticons",
      // override default upload handler to simulate successful upload
      file_picker_callback: function(cb, value, meta) {
          var input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", "image/*");
          input.onchange = function() {
              var file = this.files[0];

              var reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = function() {
                  var id = "blobid" + new Date().getTime();
                  var blobCache = tinymce.activeEditor.editorUpload.blobCache;
                  var base64 = reader.result.split(",")[1];
                  var blobInfo = blobCache.create(id, file, base64);
                  blobCache.add(blobInfo);
                  cb(blobInfo.blobUri(), { title: file.name });
              };
          };
          input.click();
      }
  });

  tinymce.init({
    selector: "textarea#full_textarea_banner",
    language: 'pt_BR',
    relative_urls: false,
    paste_data_images: true,
    image_title: true,
    automatic_uploads: true,
    images_upload_url: "/api/upload",
    file_picker_types: "image",
    height : "420",
    plugins: [
        "advlist autolink lists charmap print preview hr anchor pagebreak",
        "searchreplace wordcount visualblocks visualchars code fullscreen",
        "insertdatetime nonbreaking save table contextmenu directionality",
        "emoticons template paste textcolor colorpicker textpattern"
    ],
    toolbar1:
        "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
    toolbar2: "print preview media | forecolor backcolor emoticons",
    // override default upload handler to simulate successful upload
    file_picker_callback: function(cb, value, meta) {
        var input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.onchange = function() {
            var file = this.files[0];

            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function() {
                var id = "blobid" + new Date().getTime();
                var blobCache = tinymce.activeEditor.editorUpload.blobCache;
                var base64 = reader.result.split(",")[1];
                var blobInfo = blobCache.create(id, file, base64);
                blobCache.add(blobInfo);
                cb(blobInfo.blobUri(), { title: file.name });
            };
        };
        input.click();
    }
});

$(function () {
  $('[data-toggle="popover"]').popover()
})