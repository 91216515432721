<template>
  <div>
    <div class="row form-group">
      <label
        class="col-xl-2 col-lg-2 col-sm-2 col-12 text-lg-right text-sm-left"
        >Tamanho*</label
      >
      <div class="col-xl-4 col-lg-4 col-sm-4 col-4 no-padding-right">
        <input
          class="form-control alter-border DisableOn"
          aria-label="Nome"
          type="text"
          name="size"
          id="size"
          v-model="form.size"
          :disabled="checked == true && form.editing == false"
        />
        <span v-if="error.val == true" class="help-block-vue">
          <strong>{{ msg_val }}</strong>
        </span>
      </div>
      <div
        class="
          height_size
          d-flex
          justify-content-start
          flex-row
          bd-highlight
          mb-3
          col-xl-2 col-lg-2 col-sm-2 col-2
          no-padding-left no-padding-right
        "
      >
        <button
          type="button"
          v-on:click="check()"
          data-toggle="tooltip"
          title="Adicionar"
          class="btn btn-flat btn-info"
          id="more_option"
        >
          <i class="fa fa-plus"></i>
        </button>
        <button
          type="button"
          data-toggle="tooltip"
          title="Cancelar"
          v-on:click="reset()"
          class="btn btn-danger btn-flat"
          id="cancel"
        >
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="col-xl-4 col-lg-4 col-sm-4 col-12">
        <fieldset>
          <legend><strong>Tamanhos Cadastrados</strong></legend>
          <span v-if="size_array.length && clickable">
            <span
              class="mr-2 px-2 py-0"
              v-for="(size, index) in size_array"
              :key="index"
            >
              <span
                class="alter-mouse rounded-pill badge"
                v-on:click="alter(size.id)"
                data-toggle="tooltip"
                title="Editar"
                :class="{
                  'badge-success': size.active,
                  'badge-secondary': !size.active,
                }"
                >{{ size.size }}</span
              >
            </span>
          </span>

          <span v-if="size_array.length && !clickable">
            <span
              class="mr-2 px-2 py-0"
              v-for="(size, index) in size_array"
              :key="index"
            >
              <span
                class="rounded-pill badge"
                :class="{
                  'badge-success': size.active,
                  'badge-secondary': !size.active,
                }"
                >{{ size.size }}</span
              >
            </span>
          </span>
        </fieldset>
      </div>
    </div>

    <div v-if="this.checked == true">
      <div class="row">
        <div class="col-xl-6 col-lg-6 col-sm-6 col-12">
          <fieldset>
            <legend><strong>Venda</strong></legend>
            <div class="row form-group">
              <label
                class="
                  col-xl-4 col-lg-4 col-sm-4 col-12
                  text-lg-right text-sm-left
                "
                for="active_size"
                >Status</label
              >
              <div class="col-xl-2 col-lg-2 col-sm-2 col-12">
                <input
                  id="active_size"
                  name="active_size"
                  type="checkbox"
                  aria-label="Nome"
                  v-model="form.active"
                  value="1"
                />
              </div>

              <label
                class="
                  col-xl-4 col-lg-4 col-sm-4 col-12
                  text-lg-right text-sm-left
                "
                for="main"
                >Principal</label
              >
              <div class="col-xl-2 col-lg-2 col-sm-2 col-12">
                <input
                  type="checkbox"
                  aria-label="main"
                  id="main"
                  name="main"
                  v-model="form.main"
                  value="1"
                  v-on:change="VerifyMain()"
                  :disabled="block_main == true && form.main == false"
                />
              </div>
            </div>

            <div class="row form-group">
              <label
                class="
                  col-xl-4 col-lg-4 col-sm-4 col-12
                  text-lg-right text-sm-left
                "
                >Preço*</label
              >
              <div class="col-xl-8 col-lg-8 col-sm-8 col-12">
                <money
                  class="form-control"
                  v-model="form.price"
                  v-bind="money"
                ></money>
                <span v-if="error.price == true" class="help-block-vue">
                  <strong>{{ msg_val }}</strong>
                </span>
              </div>
            </div>

            <div class="row form-group">
              <label
                class="
                  col-xl-4 col-lg-4 col-sm-4 col-12
                  text-lg-right text-sm-left
                "
                >Promoção</label
              >
              <div class="col-xl-8 col-lg-8 col-sm-8 col-12">
                <money
                  class="form-control"
                  v-model="form.promo_price"
                  v-bind="money"
                ></money>
              </div>
            </div>

            <div class="row form-group">
              <label
                class="
                  col-xl-4 col-lg-4 col-sm-4 col-12
                  text-lg-right text-sm-left
                "
                >Quantidade*</label
              >
              <div class="col-xl-8 col-lg-8 col-sm-8 col-12">
                <input
                  class="form-control"
                  aria-label="Nome"
                  v-model="form.quantity"
                  type="number"
                />
                <span v-if="error.quantity == true" class="help-block-vue">
                  <strong>{{ msg_val }}</strong>
                </span>
              </div>
            </div>

            <div class="row form-group">
              <label
                class="
                  col-xl-4 col-lg-4 col-sm-4 col-12
                  text-lg-right text-sm-left
                "
                >SKU</label
              >
              <div class="col-xl-8 col-lg-8 col-sm-8 col-12">
                <input
                  class="form-control"
                  v-model="form.sku"
                  aria-label="Nome"
                  type="text"
                />
              </div>
            </div>

            <div class="row form-group">
              <label
                class="
                  col-xl-4 col-lg-4 col-sm-4 col-12
                  text-lg-right text-sm-left
                "
                >Código de Barras</label
              >
              <div class="col-xl-8 col-lg-8 col-sm-8 col-12">
                <input
                  class="form-control"
                  aria-label="Nome"
                  type="text"
                  v-model="form.bar_code"
                />
              </div>
            </div>
          </fieldset>
        </div>

        <div class="col-xl-6 col-lg-6 col-sm-6 col-12">
          <fieldset>
            <legend><strong>Logística</strong></legend>

            <div class="row form-group">
              <label
                class="
                  col-xl-4 col-lg-4 col-sm-4 col-12
                  text-lg-right text-sm-left
                "
                >Comprimento*</label
              >
              <div class="col-xl-8 col-lg-8 col-sm-8 col-12">
                <input
                  class="form-control"
                  type="number"
                  v-model="form.length"
                />
                <span v-if="error.length == true" class="help-block-vue">
                  <strong>{{ msg_val }}</strong>
                </span>
              </div>
            </div>
            <div class="row form-group">
              <label
                class="
                  col-xl-4 col-lg-4 col-sm-4 col-12
                  text-lg-right text-sm-left
                "
                >Altura*</label
              >
              <div class="col-xl-8 col-lg-8 col-sm-8 col-12">
                <input
                  class="form-control"
                  v-model="form.height"
                  type="number"
                />
                <span v-if="error.height == true" class="help-block-vue">
                  <strong>{{ msg_val }}</strong>
                </span>
              </div>
            </div>
            <div class="row form-group">
              <label
                class="
                  col-xl-4 col-lg-4 col-sm-4 col-12
                  text-lg-right text-sm-left
                "
                >Largura*</label
              >
              <div class="col-xl-8 col-lg-8 col-sm-8 col-12">
                <input
                  class="form-control"
                  v-model="form.width"
                  type="number"
                />
                <span v-if="error.width == true" class="help-block-vue">
                  <strong>{{ msg_val }}</strong>
                </span>
              </div>
            </div>
            <div class="row form-group">
              <label
                class="
                  col-xl-4 col-lg-4 col-sm-4 col-12
                  text-lg-right text-sm-left
                "
                >Peso*</label
              >
              <div class="col-xl-8 col-lg-8 col-sm-8 col-12">
                <input
                  class="form-control"
                  v-model="form.weight"
                  type="number"
                />
                <span v-if="error.weight == true" class="help-block-vue">
                  <strong>{{ msg_val }}</strong>
                </span>
              </div>
            </div>
          </fieldset>
          <div class="d-flex justify-content-end mt-5">
            <button
              v-on:click="check()"
              class="btn btn-flat btn-success"
              type="button"
            >
              <i class="fa fa-check"></i> Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
import axios from "axios";
import DataTable from "./DataTable.vue";
export default {
  components: { DataTable },
  props: ["edit"],
  data() {
    return {
      show_color: "success",
      clickable: false,
      temp_create: [],
      temp_index: "",
      temp_product_id: "",
      edit_val: [],
      size_array: [],
      msg_val: "",
      checked: false,
      block_main: false,
      error: {
        val: false,
        price: false,
        quantity: false,
        length: false,
        height: false,
        width: false,
        weight: false,
      },
      form: {
        active: true,
        id: "",
        promo_price: "",
        bar_code: "",
        size: "",
        price: "",
        quantity: "",
        length: "",
        height: "",
        width: "",
        weight: "",
        product_id: "",
        editing: false,
        main: 0,
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false,
      },
    };
  },

  created() {
    if (!this.edit) {
      if (document.getElementById("temporary").value) {
        this.size_array = JSON.parse(document.getElementById("temporary").value);
        for (let i = 0; i < this.size_array.length; i++) {
          this.temp_create.push(this.size_array[i]);
          
        }
      }
    } else if (this.edit) {
      this.block_main = true;
      this.edit_val = JSON.parse(this.edit);
      var main_count = 0;
      this.clickable = this.edit;
      this.temp_product_id = this.edit_val[0].product_id;
      for (var k in this.edit_val) {
        if (this.edit_val[k].active == 0) {
          this.edit_val[k].active = false;
        } else {
          this.edit_val[k].active = true;
        }
        if (this.edit_val[k].main == 0) {
          this.edit_val[k].main = false;
        } else {
          this.edit_val[k].main = true;
          main_count = 1;
        }
        this.size_array.push({
          size: this.edit_val[k].size,
          id: this.edit_val[k].id,
          active: this.edit_val[k].active,
          main: this.edit_val[k].main,
        });
      }
      if (main_count == 0) {
        this.block_main = false;
      }
    }
  },

  methods: {
    verify() {
      if (this.form.price <= 0) {
        this.error.price = true;
        this.msg_val = "O preço deve ser maior que zero!";
        return false;
      } else {
        this.error.price = false;
      }

      if (this.form.quantity <= 0) {
        this.error.quantity = true;
        this.msg_val = "A quantidade deve ser maior que zero!";
        return false;
      } else {
        this.error.quantity = false;
      }

      if (this.form.length <= 0) {
        this.error.length = true;
        this.msg_val = "O comprimento precisa ser maior que zero!";
        return false;
      } else {
        this.error.length = false;
      }

      if (this.form.height <= 0) {
        this.error.height = true;
        this.msg_val = "A altura precisa ser maior que zero!";
        return false;
      } else {
        this.error.height = false;
      }

      if (this.form.width <= 0) {
        this.error.width = true;
        this.msg_val = "A largura precisa ser maior que zero!";
        return false;
      } else {
        this.error.width = false;
      }

      if (this.form.weight <= 0) {
        this.error.weight = true;
        this.msg_val = "O peso precisa ser maior que zero!";
        return false;
      } else {
        this.error.weight = false;
      }

      this.SentController();
    },

    VerifyMain() {
      if (this.form.main == false) {
        this.block_main = false;
      }
    },

    SentController() {
      if (this.form.editing == false && !this.edit) {
        this.size_array.push({
          size: this.form.size,
          active: this.form.active,
          main: this.form.main,
        });
        this.edit_val.push(this.form);
        this.temp_create.push(this.form);
        this.temp(this.temp_create);
      } else {
        this.form.product_id = this.temp_product_id;
        var count = 0;

        for (var x in this.edit_val) {
          if (this.edit_val[x].main == true) {
            count += 1;
          }
        }

        axios
          .post("/api/products", this.form)
          .then((response) => {
            if (response.data == "unblock") {
              this.block_main = false;
            } else if (response.data == "error") {
              Toast.fire(
                "Já há um tamanho cadastrado como principal!",
                "",
                "error"
              );
              return false;
            } else if (response.data > 0) {
              this.form.id = response.data;
              this.size_array.push({
                id: response.data,
                size: this.form.size,
                active: this.form.active,
                main: this.form.main,
              });
              this.edit_val.push(this.form);
            }
            this.reset();
          })
          .catch(function (error) {
            console.log(error.response);
          });
      }
    },

    check() {
      if (this.form.editing == true && this.checked == true) {
        for (var x in this.edit_val) {
          if (this.edit_val[x].id == this.temp_index) {
            this.edit_val[x].active = this.form.active;
            this.edit_val[x].main = this.form.main;
            this.edit_val[x].promo_price = this.form.promo_price;
            this.edit_val[x].bar_code = this.form.bar_code;
            this.edit_val[x].size = this.form.size;
            this.edit_val[x].price = this.form.price;
            this.edit_val[x].quantity = this.form.quantity;
            this.edit_val[x].sku = this.form.sku;
            this.edit_val[x].length = this.form.length;
            this.edit_val[x].height = this.form.height;
            this.edit_val[x].width = this.form.width;
            this.edit_val[x].weight = this.form.weight;
            this.size_array[x].size = this.edit_val[x].size;
            this.size_array[x].active = this.edit_val[x].active;
            this.size_array[x].main = this.edit_val[x].main;
          }
          this.edit_val[x].editing = this.form.editing;
        }
      }

      if (!this.form.size || this.form.size <= 0) {
        this.error.val = true;
        this.msg_val = "O campo tamanho não pode estar vazio!";
        return false;
      } else {
        document.getElementById("save_button").disabled = true;
        this.error.val = false;
      }

      if (this.checked == true) {
        this.verify();
      } else {
        this.checked = true;
      }
    },

    temp(create) {
      document.getElementById("temporary").value = JSON.stringify(create);
      this.reset();
    },

    alter(index) {
      document.getElementById("save_button").disabled = true;
      for (var j in this.edit_val) {
        if (this.edit_val[j].main == 1) {
          this.block_main = true;
        }
        if (this.edit_val[j].id == index) {
          this.form = {
            active: this.edit_val[j].active,
            id: this.edit_val[j].id,
            promo_price: this.edit_val[j].promo_price,
            bar_code: this.edit_val[j].bar_code,
            size: this.edit_val[j].size,
            price: this.edit_val[j].price,
            quantity: this.edit_val[j].quantity,
            sku: this.edit_val[j].sku,
            length: this.edit_val[j].length,
            height: this.edit_val[j].height,
            width: this.edit_val[j].width,
            weight: this.edit_val[j].weight,
            main: this.edit_val[j].main,
          };
          this.checked = true;
          this.form.editing = true;
          this.temp_index = index;
        }
      }
    },

    reset() {
      this.msg_val = "";
      this.checked = false;
      this.temp_index = "";
      this.error = {
        val: false,
        price: false,
        quantity: false,
        length: false,
        height: false,
        width: false,
        weight: false,
      };

      this.form = {
        active: true,
        id: "",
        promo_price: "",
        bar_code: "",
        size: "",
        price: "",
        quantity: "",
        sku: "",
        length: "",
        height: "",
        width: "",
        weight: "",
        product_id: this.temp_product_id,
        main: 0,
        editing: false,
      };
      document.getElementById("save_button").disabled = false;
    },
  },
};
</script> 

<style lang="scss">
fieldset {
  border: 2px solid #eee;
  padding: 16px;
}
legend {
  width: auto !important;
  padding-right: 1em;
  padding-left: 1em;
}
.help-block-vue {
  color: #dd4b39;
}
.height_size {
  height: 100%;
}
.alter-mouse:hover {
  color: black;
  transition: 0.5s;
  cursor: pointer;
}
</style>