<template>
  <div class="slider" v-if="banners.length != 0">
    <div class="text">
      <p>
        {{ banners[slide].text }}
        <span>{{ banners[slide].highlight }}</span>
      </p>
      <a :href="banners[slide].link">Saiba mais</a>
    </div>
    <transition name="slide-fade" mode="in-out">
      <div class="circle" :key="slide">
        <div class="back"></div>
        <div class="preview">
          <img loading="lazy" :src="banners[slide].image" alt="Telha ON24" />
        </div>
      </div>
    </transition>
    <div class="sample">
      <transition name="slide-fade" mode="in-out">
        <img
          loading="lazy"
          :src="banners[slide].preview"
          alt="Exemplo Telha ON24"
          :key="slide"
        />
      </transition>
      <div class="control">
        <div class="prev" @click="prevSlide(true)">
          <span class="mdi mdi-arrow-left"></span>
        </div>
        <div class="next" @click="nextSlide(true)">
          <span class="mdi mdi-arrow-right"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition-delay: 1s;
  transition: all 1s !important;
}
.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}
.slider {
  width: 100%;
  height: 50vh;
  max-height: 468px;
  background-color: #1b2d47;
  color: #fff;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  .text {
    width: 55%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 12% 0 15%;
    position: absolute;
    left: 0;
    top: 0;
    p {
      font-size: 1.8rem;
      line-height: 2.5rem;
      span {
        color: #61c5e8;
      }
    }
    a {
      margin-top: 7%;
      color: #fff;
      font-size: 0.7rem;
      padding: 0.5rem 2.3rem;
      border: 1px solid #61c5e8;
      border-radius: 4px;
      &:hover {
        box-shadow: 0 0 6px 2px rgba(97, 197, 232, 0.2),
          inset 0 0 6px 2px rgba(97, 197, 232, 0.2);
      }
    }
  }
  .sample {
    width: 45%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      position: absolute;
      top: 0;
      left: 0;
    }
    .control {
      position: absolute;
      bottom: 5%;
      left: 50%;
      min-width: 88px;
      display: flex;
      .prev,
      .next {
        width: 40px;
        height: 40px;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        &:hover {
          cursor: pointer !important;
          box-shadow: 0 0 12px 2px rgba(255, 255, 255, 0.2);
          span {
            cursor: pointer !important;
          }
        }
      }
      .prev {
        background-color: rgba(255, 255, 255, 0.3);
        margin-right: 10%;
      }
      .next {
        background-color: rgba(255, 255, 255, 0.7);
        color: #333;
      }
    }
  }
  .circle {
    z-index: 2;
    .preview,
    .back {
      width: 40vh;
      max-width: 375px;
      height: 40vh;
      max-height: 375px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 55%;
      transform: translate(-50%, -50%);
      overflow: hidden;
    }
    .preview {
      background-color: #fff;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: right center;
      }
    }
    .back {
      transform: translate(-45%, -50%);
      background-color: #61c5e8;
    }
  }
}
@media (max-width: 1024px) {
  .slider {
    height: 40vh;
    .text {
      padding: 0 12% 0 10%;
    }
    .circle {
      .preview,
      .back {
        width: 35vh;
        height: 35vh;
        max-width: 300px;
        max-height: 300px;
      }
    }
  }
}
@media (max-width: 768px) {
  .slider {
    .text {
      p {
        font-size: 1.2rem;
        line-height: 1.2rem;
      }
    }
  }
}
@media (max-width: 480px) {
  .slider {
    height: 50vh;
    flex-direction: column;
    .text {
      top: 25vh;
      height: 50%;
      width: 65%;
      padding: 0 13% 0 5%;
      p {
        font-size: 1rem;
        line-height: 1rem;
      }
      a {
        margin-top: 3%;
        padding: 0.25rem 2.3rem;
      }
    }
    .sample {
      width: 100%;
      height: 50%;
      .control {
        left: 20%;
      }
    }
    .circle {
      .preview,
      .back {
        width: 25vh;
        height: 25vh;
        left: 75%;
      }
    }
  }
}
</style>

<script>
export default {
  props: ["banners"],
  data() {
    return {
      slide: 0,
      wait: 5000,
      time: "",
    };
  },
  methods: {
    nextSlide(user) {
      if (user) {
        clearTimeout(this.time);
        this.wait = 15000;
      }
      this.slide == this.banners.length - 1
        ? (this.slide = 0)
        : (this.slide += 1);
      this.autoSlide();
    },
    prevSlide(user) {
      if (user) {
        clearTimeout(this.time);
        this.wait = 15000;
      }
      this.slide == 0
        ? (this.slide = this.banners.length - 1)
        : (this.slide -= 1);
      this.autoSlide();
    },
    autoSlide() {
      if (this.banners.length != 1) {
        this.time = setTimeout(() => {
          this.wait = 5000;
          this.nextSlide(false);
        }, this.wait);
      }
    },
  },
  mounted() {
    this.autoSlide();
  },
};
</script>