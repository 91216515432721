<template>
  <div class="nav-tabs-custom">
    <ul
      class="d-flex nav nav-tabs"
      :class="{ 'd-flex justify-content-between': exporter == true }"
    >
      <li
        v-for="(tab, index) in tabs_prop"
        :key="index"
        v-bind:class="{ active: activeTab_prop == index }"
      >
        <a
          v-bind:href="'#tab_' + index"
          @click="activeTab_prop = index"
          data-toggle="tab"
          ><i v-bind:class="tab.icon"></i> {{ tab.title }}</a
        >
      </li>
      <li class="adjust_right" v-if="exporter == true">
        <span>
          <button
            v-on:click="Export()"
            data-toggle="tooltip"
            title="Exportar Clientes"
            type="button"
            data-placement="left"
            class="btn btn-flat btn-success mt-1"
          >
            <i class="fa fa-download"></i>
          </button>
        </span>
      </li>
      <li class="adjust_right ml-sm-auto ml-sm-0 ml-2" v-if="edit == true">
        <span>
          <button
            onclick="location.href='/cms/shop_menu/shop_services/alter_lot';"
            data-toggle="tooltip"
            title="Alteração em Lote"
            type="button"
            data-placement="left"
            class="btn btn-flat btn-success mt-1 teste1"
          >
            <i class="fa fa-pencil font-alter"
              ><span style="font-family: 'Source Sans Pro', sans-serif">
                Alteração em Lote</span
              ></i
            >
          </button>
        </span>
      </li>
    </ul>

    <div class="tab-content">
      <div
        v-for="(tab, index) in tabs_prop"
        :key="index"
        class="tab-pane"
        v-bind:class="{ active: activeTab_prop == index }"
        v-bind:id="'tab_' + index"
      >
        <slot v-bind:name="'tabslot_' + index"></slot>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.adjust_right {
  padding-right: 15px;
}
</style>
<script>
import axios from "axios";
export default {
  props: ["tabs", "activeTab", "exporter", "edit"],
  data() {
    return {
      tabs_prop: this.tabs,
      activeTab_prop: this.activeTab,
    };
  },
  methods: {
    Export() {
      const FileDownload = require("js-file-download");
      axios({
        url: "/api/clients",
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          console.log(response.data);
          FileDownload(response.data, "clientes.csv");
        })
        .catch(function (error) {
          console.log(error.response);
        });
    },
  },
};
</script>
