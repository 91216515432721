<template>
  <div>
    <fieldset class="mt-3">
      <legend>
        <strong v-if="!form.editing">Cadastrar Faixas de CEP</strong
        ><strong v-else>Editar Faixa de CEP</strong>
      </legend>
      <div class="row form-group">
        <label
          class="col-xl-2 col-lg-2 col-sm-2 col-12 text-lg-right text-sm-left"
          >Frete grátis</label
        >
        <div class="col-xl-10 col-lg-10 col-sm-10 col-12">
          <input
            v-model="form.free_shipping"
            aria-label="Nome"
            type="checkbox"
            name="email"
            id="email"
          />
        </div>
      </div>

      <div class="row form-group">
        <label
          class="col-xl-2 col-lg-2 col-sm-2 col-12 text-lg-right text-sm-left"
          >Apartir de</label
        >
        <div class="col-xl-2 col-lg-2 col-sm-4 col-12">
          <money
            class="form-control DisableOn"
            v-model="form.price"
            v-bind="money"
            :disabled="!form.free_shipping"
          ></money>
        </div>
      </div>

      <div class="row form-group">
        <label
          class="col-xl-2 col-lg-2 col-sm-2 col-12 text-lg-right text-sm-left"
          >Frete Fixo</label
        >
        <div class="col-xl-2 col-lg-2 col-sm-4 col-12">
          <money
            class="form-control"
            v-model="form.fixed_price"
            v-bind="money"
            required
          ></money>
        </div>
      </div>

      <div class="row form-group">
        <label
          class="col-xl-2 col-lg-2 col-sm-2 col-12 text-lg-right text-sm-left"
          >Faixa de endereços
          <a
            href="https://buscacepinter.correios.com.br/app/faixa_cep_uf_localidade/index.php"
            target="_blank"
            ><i
              class="alter-mouse fa fa-question-circle"
              data-toggle="tooltip"
              data-placement="right"
              title="Sobre"
            ></i
          ></a>
        </label>
        <div
          class="
            d-md-flex
            justify-content-between
            col-xl-10 col-lg-10 col-sm-10 col-12
          "
        >
          <input
            min="1000000"
            max="99999999"
            type="number"
            v-model="form.start_shipping"
            placeholder="Faixa inicial"
            class="mb-md-0 mb-2 form-control"
          />
          <input
            min="1000000"
            max="99999999"
            type="number"
            v-model="form.end_shipping"
            placeholder="Faixa final"
            class="ml-md-2 form-control"
          />
        </div>

        <span class="col-xl-2 col-lg-2 col-sm-2 col-12"></span>
        <span
          v-if="error_msg"
          class="col-xl-10 col-lg-10 col-sm-10 col-12 help-block"
          style="color: red"
        >
          <strong v-if="error_msg == 1"
            >As faixas devem ter de 7 a 8 digitos e não podem estar em
            branco</strong
          >
          <strong v-if="error_msg == 2">Marque o frete grátis ou insira um valor para o frete fixo</strong>
        </span>
      </div>
      <div class="d-flex justify-content-end">
        <button
          type="button"
          v-on:click="Cancel()"
          class="btn btn-flat btn-default mr-1"
          v-if="form.editing"
        >
          Cancelar
        </button>
        <button
          type="button"
          v-on:click="Verify()"
          class="btn btn-flat btn-success"
        >
          <span v-if="!form.editing">Cadastrar</span>
          <span v-else>Salvar</span>
        </button>
      </div>
    </fieldset>

    <fieldset v-if="shipping_list.length > 0" class="mt-2">
      <legend><strong>Faixas Cadastradas</strong></legend>
      <div v-for="(item, index) in shipping_list" :key="index" class="mb-3">
        <div class="form-group">
          Frete grátis:
          <span class="badge badge-success" v-if="item.free_shipping == true"
            >Sim</span
          ><span class="badge badge-danger" v-else>Não</span>
        </div>

        <div
          v-if="item.price > 0 && item.free_shipping == true"
          class="form-group"
        >
          <span
            >Apartir de: <strong>R${{ item.price }}</strong></span
          >
        </div>

        <div class="form-group" v-if="item.fixed_price > 0">
          <span
            >Frete Fixo: <strong>R${{ item.fixed_price }}</strong></span
          >
        </div>

        <div class="form-group">
          <span
            >Faixa CEP: <strong>{{ item.start_shipping }}</strong> até
            <strong>{{ item.end_shipping }}</strong></span
          >
          <div class="d-flex justify-content-end mt-sm-0 mt-2">
            <button
              class="btn btn-flat btn-info"
              type="button"
              v-on:click="Alter(item.id)"
              :disabled="form.editing"
            >
              Editar
            </button>
            <button
              class="btn btn-flat btn-danger ml-1"
              type="button"
              v-on:click="Remove(item.id)"
              :disabled="form.editing"
            >
              Apagar
            </button>
          </div>
        </div>

        <hr />
      </div>
    </fieldset>
  </div>
</template>
 
<script>
export default {
  props: ["edit"],
  data() {
    return {
      counter: 1,
      history: "",
      temp: "",
      delete_list: [],
      editing_atm: "",
      value: "",
      shipping_list: [],
      error_msg: false,
      form: {
        id: "",
        free_shipping: false,
        start_shipping: "",
        end_shipping: "",
        price: 0,
        fixed_price: 0,
        editing: false,
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false,
      },
    };
  },

  created() {
    if (this.edit != "[]") {
      this.editing_atm = JSON.parse(this.edit);
      this.Edit();
    }
  },

  methods: {
    Verify() {
      if (
        !this.form.start_shipping ||
        this.form.start_shipping.length < 7 ||
        this.form.start_shipping.length > 8 ||
        !this.form.end_shipping ||
        this.form.end_shipping.length < 7 ||
        this.form.end_shipping.length > 8 ||
        this.form.start_shipping > this.form.end_shipping
      ) {
        this.error_msg = 1;
      } else if (
        this.form.free_shipping == false &&
        this.form.fixed_price <= 0
      ) {
        this.error_msg = 2;
      } else {
        this.error_msg = false;
        this.List();
      }
    },

    Edit() {
      for (var i = 0; i < this.editing_atm.length; i++) {
        this.form = this.editing_atm[i];
        this.shipping_list.push(this.form);
      }
      this.Reset();
    },

    List() {
      document.getElementById("save_button").disabled = false;
      if (this.form.editing == true) {
        this.shipping_list.splice(this.temp, 0, this.form);
      } else {
        this.form.id = "a" + this.counter;
        this.counter = this.counter + 1;
        this.shipping_list.push(this.form);
      }
      document.getElementById("temp_delivery").value = JSON.stringify(
        this.shipping_list
      );
      this.Reset();
    },

    Alter(id) {
      document.getElementById("save_button").disabled = true;
      for (var x in this.shipping_list) {
        if (this.shipping_list[x].id == id) {
          this.form = this.shipping_list[x];
          this.form.editing = true;
          this.shipping_list.splice(x, 1);
          this.temp = x;
          this.history = JSON.stringify(this.form);
        }
      }
    },

    Cancel() {
      document.getElementById("save_button").disabled = false;
      this.shipping_list.splice(this.temp, 0, JSON.parse(this.history));
      this.Reset();
    },

    Remove(id) {
      for (var k in this.shipping_list) {
        if (this.shipping_list[k].id == id) {
          this.delete_list.push(id);
          this.shipping_list.splice(k, 1);
          document.getElementById("delete_list").value = this.delete_list;
        }
      }
    },

    Reset() {
      this.error_msg = false;
      this.value = "";
      this.form = {
        id: "",
        free_shipping: false,
        start_shipping: "",
        price: 0,
        end_shipping: "",
        fixed_price: 0,
        editing: false,
      };
      this.x = "";
    },
  },
};
</script> 