<template>
  <div>
    <div class="row mt-3 form-group">
      <label
        class="col-xl-2 col-lg-2 col-sm-2 col-12 text-lg-right text-sm-left"
        >Máximo de Parcelas*</label
      >
      <div class="col-xl-10 col-lg-10 col-sm-10 col-12">
        <input
          min="1"
          max="12"
          class="form-control"
          type="number"
          name="max_parcels"
          id="max_parcels"
          v-model="taxes.max_parcels"
          required
        />
      </div>
    </div>

<div class="row">
    <label class="text-lg-right text-sm-left col-sm-2">Taxa de juros por parcela (%)</label>
    <div class="form-group row col-10">
      <div
        class="form-group col-sm-4 col-lg-3 col-xl-2 col-12 input-group"
        v-if="taxes.max_parcels >= 1 && taxes.max_parcels < 13"
      >
        <div class="input-group-append">
          <span class="input-group-text">1x</span>
        </div>
        <input
          type="number"
          min="1"
          name="tax_parcel_1"
          placeholder="%"
          class="form-control"
          id="tax_parcel_1"
          v-model="taxes.tax_parcel_1"
          v-on:blur="SendToController()"
          required
        />
      </div>

      <div
        class="form-group col-sm-4 col-lg-3 col-xl-2 col-12 input-group"
        v-if="taxes.max_parcels >= 2 && taxes.max_parcels < 13"
      >
        <div class="input-group-append">
          <span class="input-group-text">2x</span>
        </div>
        <input
          type="number"
          min="1"
          name="tax_parcel_2"
          placeholder="%"
          class="form-control"
          id="tax_parcel_2"
          v-model="taxes.tax_parcel_2"
          v-on:blur="SendToController()"
          required
        />
      </div>

      <div
        class="form-group col-sm-4 col-lg-3 col-xl-2 col-12 input-group"
        v-if="taxes.max_parcels >= 3 && taxes.max_parcels < 13"
      >
        <div class="input-group-append">
          <span class="input-group-text">3x</span>
        </div>
        <input
          type="number"
          min="1"
          name="tax_parcel_3"
          placeholder="%"
          class="form-control"
          id="tax_parcel_3"
          v-model="taxes.tax_parcel_3"
          v-on:blur="SendToController()"
          required
        />
      </div>

      <div
        class="form-group col-sm-4 col-lg-3 col-xl-2 col-12 input-group"
        v-if="taxes.max_parcels >= 4 && taxes.max_parcels < 13"
      >
        <div class="input-group-append">
          <span class="input-group-text">4x</span>
        </div>
        <input
          type="number"
          min="1"
          name="tax_parcel_4"
          placeholder="%"
          class="form-control"
          id="tax_parcel_4"
          v-model="taxes.tax_parcel_4"
          v-on:blur="SendToController()"
          required
        />
      </div>

      <div
        class="form-group col-sm-4 col-lg-3 col-xl-2 col-12 input-group"
        v-if="taxes.max_parcels >= 5 && taxes.max_parcels < 13"
      >
        <div class="input-group-append">
          <span class="input-group-text">5x</span>
        </div>
        <input
          type="number"
          min="1"
          name="tax_parcel_5"
          placeholder="%"
          class="form-control"
          id="tax_parcel_5"
          v-model="taxes.tax_parcel_5"
          v-on:blur="SendToController()"
          required
        />
      </div>

      <div
        class="form-group col-sm-4 col-lg-3 col-xl-2 col-12 input-group"
        v-if="taxes.max_parcels >= 6 && taxes.max_parcels < 13"
      >
        <div class="input-group-append">
          <span class="input-group-text">6x</span>
        </div>
        <input
          type="number"
          min="1"
          name="tax_parcel_6"
          placeholder="%"
          class="form-control"
          id="tax_parcel_6"
          v-model="taxes.tax_parcel_6"
          v-on:blur="SendToController()"
          required
        />
      </div>

      <div
        class="form-group col-sm-4 col-lg-3 col-xl-2 col-12 input-group"
        v-if="taxes.max_parcels >= 7 && taxes.max_parcels < 13"
      >
        <div class="input-group-append">
          <span class="input-group-text">7x</span>
        </div>
        <input
          type="number"
          min="1"
          name="tax_parcel_7"
          placeholder="%"
          class="form-control"
          id="tax_parcel_7"
          v-model="taxes.tax_parcel_7"
          v-on:blur="SendToController()"
          required
        />
      </div>

      <div
        class="form-group col-sm-4 col-lg-3 col-xl-2 col-12 input-group"
        v-if="taxes.max_parcels >= 8 && taxes.max_parcels < 13"
      >
        <div class="input-group-append">
          <span class="input-group-text">8x</span>
        </div>
        <input
          type="number"
          min="1"
          name="tax_parcel_8"
          placeholder="%"
          class="form-control"
          id="tax_parcel_8"
          v-model="taxes.tax_parcel_8"
          v-on:blur="SendToController()"
          required
        />
      </div>

      <div
        class="form-group col-sm-4 col-lg-3 col-xl-2 col-12 input-group"
        v-if="taxes.max_parcels >= 9 && taxes.max_parcels < 13"
      >
        <div class="input-group-append">
          <span class="input-group-text">9x</span>
        </div>
        <input
          type="number"
          min="1"
          name="tax_parcel_9"
          placeholder="%"
          class="form-control"
          id="tax_parcel_9"
          v-model="taxes.tax_parcel_9"
          v-on:blur="SendToController()"
          required
        />
      </div>

      <div
        class="form-group col-sm-4 col-lg-3 col-xl-2 col-12 input-group"
        v-if="taxes.max_parcels >= 10 && taxes.max_parcels < 13"
      >
        <div class="input-group-append">
          <span class="input-group-text">10x</span>
        </div>
        <input
          type="number"
          min="1"
          name="tax_parcel_10"
          placeholder="%"
          class="form-control"
          id="tax_parcel_10"
          v-model="taxes.tax_parcel_10"
          v-on:blur="SendToController()"
          required
        />
      </div>

      <div
        class="form-group col-sm-4 col-lg-3 col-xl-2 col-12 input-group"
        v-if="taxes.max_parcels >= 11 && taxes.max_parcels < 13"
      >
        <div class="input-group-append">
          <span class="input-group-text">11x</span>
        </div>
        <input
          type="number"
          min="1"
          name="tax_parcel_11"
          placeholder="%"
          class="form-control"
          id="tax_parcel_11"
          v-model="taxes.tax_parcel_11"
          v-on:blur="SendToController()"
          required
        />
      </div>

      <div
        class="form-group col-sm-4 col-lg-3 col-xl-2 col-12 input-group"
        v-if="taxes.max_parcels >= 12 && taxes.max_parcels < 13"
      >
        <div class="input-group-append">
          <span class="input-group-text">12x</span>
        </div>
        <input
          type="number"
          min="1"
          name="tax_parcel_12"
          placeholder="%"
          class="form-control"
          id="tax_parcel_12"
          v-model="taxes.tax_parcel_12"
          v-on:blur="SendToController()"
          required
        />
      </div>
      <span class="col-xl-6 col-lg-6 col-sm-6 col-12 help-block" style="color:red;" v-if="taxes.max_parcels <= 0 || taxes.max_parcels > 12">
        <strong>O Número de parcelas deve ser de 1 a 12</strong>
      </span>
    </div>
</div>
  </div>
</template>

<script>
export default {
  props: ["edit"],
  data() {
    return {
      editing: "",
      taxes_edit: "",
      taxes: {
        max_parcels: "",
        tax_parcel_1: "",
        tax_parcel_2: "",
        tax_parcel_3: "",
        tax_parcel_4: "",
        tax_parcel_5: "",
        tax_parcel_6: "",
        tax_parcel_7: "",
        tax_parcel_8: "",
        tax_parcel_9: "",
        tax_parcel_10: "",
        tax_parcel_11: "",
        tax_parcel_12: "",
      },
    };
  },

  created() {
    this.editing = Object.entries(JSON.parse(this.edit));
    this.Edit();
  },

  methods: {
    SendToController() {
      document.getElementById("temp_parcels").value = JSON.stringify(
        this.taxes
      );
    },

    Edit() {
      this.taxes_edit = Object.entries(this.taxes);
      for (var i = 1; i <= this.editing[0][1]; i++) {
        this.taxes_edit[i][1] = this.editing[i][1];
      }
      this.taxes = Object.fromEntries(this.taxes_edit);
      this.taxes.max_parcels = this.editing[0][1];
    },
  },
};
</script> 