<template>
  <div class="col-sm-6">
    <div class="row">
      <label for="cart_step_id" class="col-sm-12 control-label text-left"
        >Status do Carrinho</label
      >
      <div class="col-sm-12">
        <select
          type="text"
          name="cart_step_id"
          class="form-control DisableOn"
          :class="{ 'mouse-alter': !block, 'mouse-block': block }"
          id="select_status"
          v-model="selection.cart_step_id"
          :disabled="block == true"
          v-on:change="verify()"
        >
          <option value="0" selected>Selecionando Produtos</option>
          <option value="1">Pedido Efetuado</option>
          <option value="2">Aguardando Pagamento</option>
          <option value="3">Pagamento Recebido</option>
          <option value="4">Produto Enviado</option>
          <option value="5">Produto Recebido</option>
          <option value="6">Pedido Cancelado</option>
        </select>
        <span v-if="error_msg != ''" class="help-block-vue mt-2">
          <strong>{{ error_msg }}</strong>
        </span>
      </div>
    </div>

    <div
      data-bs-backdrop="static"
      class="modal fade"
      id="ModalStatus"
      role="dialog"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Confirmação</h4>
          </div>
          <div class="modal-body">
            <p>Deseja realmente alterar o Status do Carrinho?</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-success"
              data-dismiss="modal"
              id="accepted"
              v-on:click="accept()"
            >
              Sim
            </button>
            <button
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
              id="declined"
              v-on:click="decline()"
            >
              Não
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 <style lang="scss">
.mouse-block {
  cursor: not-allowed;
}
</style>
<script>
import axios from "axios";
export default {
  props: [
    "user_atm",
    "select_atm",
    "cart",
    "user_name",
    "client_atm",
    "order_atm",
  ],
  data() {
    return {
      selection: {
        cart_step_id: "",
        id: this.cart,
        message: "",
        client_id: this.client_atm,
        order_id: this.order_atm,
      },
      block: true,
      error_msg: "",
      show: false,
      temp: "",
    };
  },
  created() {
    this.selection.cart_step_id = this.select_atm;
    if (this.user_atm == 1) {
      this.block = false;
    }
  },

  mounted() {
    console.log(this.selection.cart_step_id);
    if (this.selection.cart_step_id != 3){
      document.getElementById('tracking_code').setAttribute("disabled","disabled");
      document.getElementById('tracking_code').setAttribute("class","form-control alter-border DisableOn");
    }
  },

  methods: {
    verify() {
      if (this.user_atm != 1) {
        this.error_msg =
          "É necessário estar logado na conta administradora para alterar este campo!";
      } else {
        this.error_msg = "";
        $("#ModalStatus").modal("show");
      }
    },
    accept() {
      this.GoToApi();
    },
    decline() {
      location.reload();
    },
    GoToApi() {
      this.temp = document.getElementById("select_status");
      this.selection.message =
        "Usuário " +
        "<strong>" +
        this.user_name +
        "</strong>" +
        " Alterou o status para " +
        "<strong>" +
        this.temp.options[this.temp.selectedIndex].text +
        "</strong>";
      axios
        .post("/api/cart_status", this.selection)
        .then((response) => {
          location.reload();
        })
        .catch(function (error) {
          console.log(error.response);
        });
    },
  },
};
</script> 