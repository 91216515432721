<template>
  <div class="signup">
    <div class="signup__nav">
      <div class="signup__nav-trick--left"></div>
      <div class="signup__nav-trick--right"></div>
      <div class="signup__nav-basebar"></div>
      <div class="signup__nav-bar" :style="{ '--step': step - 1 }"></div>
      <div
        class="signup__nav-item"
        v-bind:class="{ active: step >= 1, great: step == 1 }"
      >
        <div class="signup__nav-item--icon">
          <i class="fa fa-bars" aria-hidden="true"></i>
        </div>
        <div class="signup__nav-item--title">Informações</div>
      </div>
      <div
        class="signup__nav-item"
        v-bind:class="{ active: step >= 2, great: step == 2 }"
      >
        <div class="signup__nav-item--icon">
          <i class="fa fa-map-marker" aria-hidden="true"></i>
        </div>
        <div class="signup__nav-item--title">Endereços</div>
      </div>
      <div
        class="signup__nav-item"
        v-bind:class="{ active: step >= 3, great: step == 3 }"
      >
        <div class="signup__nav-item--icon">
          <i class="fa fa-truck" aria-hidden="true"></i>
        </div>
        <div class="signup__nav-item--title">Formas de envio</div>
      </div>
      <div
        class="signup__nav-item"
        v-bind:class="{ active: step >= 4, great: step == 4 }"
      >
        <div class="signup__nav-item--icon">
          <i class="fa fa-usd" aria-hidden="true"></i>
        </div>
        <div class="signup__nav-item--title">Pagamento</div>
      </div>
      <div
        class="signup__nav-item"
        v-bind:class="{ active: step >= 5, great: step == 5 }"
      >
        <div class="signup__nav-item--icon">
          <i class="fa fa-smile-o" aria-hidden="true"></i>
        </div>
        <div class="signup__nav-item--title">Finalizar</div>
      </div>
    </div>
    <div
      class="signup__content"
      :style="{ '--height': itemHeight + 50 + 'px' }"
    >
      <div
        class="signup__content-item"
        ref="item1"
        v-bind:class="{ active: step == 1 }"
      >
        <form action="">
          <div class="type">
            <div class="type-select">
              <input
                type="radio"
                name="type"
                id="fisica"
                value="cpf"
                v-model="type"
              />
              <label for="fisica">Pessoa física</label>
            </div>
            <div class="type-select">
              <input
                type="radio"
                name="type"
                id="juridica"
                value="cnpj"
                v-model="type"
              />
              <label for="juridica">Pessoa jurídica</label>
            </div>
          </div>
          <input type="text" placeholder="Nome e sobrenome" name="nome" v-if="type == 'cpf'" />
          <input type="text" placeholder="Nome" name="nome" v-if="type == 'cnpj'" />
          <the-mask :name="'cpf'" :placeholder="'CPF'" :mask="['###.###.###-##']" v-if="type == 'cpf'" />
          <the-mask :name="'cnpj'" :placeholder="'CNPJ'" :mask="['##.###;###/####-##']" v-if="type == 'cnpj'" />
          <input type="email" placeholder="E-mail" name="email" />
          <the-mask :name="'telefone'" :placeholder="'Telefone'" :mask="['(##) ####-####']" />
          <the-mask :name="'celular'" :placeholder="'Celular'" :mask="['(##) ####-####', '(##) #####-####']" v-if="type == 'cpf'" />
          <input type="password" placeholder="Senha para acesso" name="senha" />
        </form>
        <a @click="nextStep()" class="next"
          >Avançar <span class="mdi mdi-arrow-right"></span
        ></a>
      </div>
      <div
        class="signup__content-item"
        ref="item2"
        v-bind:class="{ active: step == 2 }"
      >
        aaaaaaaaaaaa
        <a @click="nextStep()" class="next"
          >Avançar <span class="mdi mdi-arrow-right"></span
        ></a>
      </div>
      <div
        class="signup__content-item"
        ref="item3"
        v-bind:class="{ active: step == 3 }"
      >
        nnnnnnnnnnn
        <a @click="nextStep()" class="next"
          >Avançar <span class="mdi mdi-arrow-right"></span
        ></a>
      </div>
      <div
        class="signup__content-item"
        ref="item4"
        v-bind:class="{ active: step == 4 }"
      >
        fffffffffffff
        <a @click="nextStep()" class="next"
          >Avançar <span class="mdi mdi-arrow-right"></span
        ></a>
      </div>
      <div
        class="signup__content-item"
        ref="item5"
        v-bind:class="{ active: step == 5 }"
      >
        ssssssss
        <a href="/" class="finish-btn">Cadastrar</a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$white: #fff;
$black: #000;
$dark-blue: #1b2d47;
$blue: #254b6a;
$light-blue: #61c5e8;
$hover-light-blue: #4b9cba;
$hover-dark-blue: #0f1927;
$input-color: #6e6d72;

.signup {
  margin-top: 4rem;
  width: 100%;
  &__nav {
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
    &-trick--left,
    &-trick--right {
      background-color: $white;
      height: 1rem;
      position: absolute;
      top: 1rem;
      width: 2rem;
      z-index: 1;
    }
    &-trick--left {
      left: -1rem;
    }
    &-trick--right {
      right: -1rem;
    }
    &-basebar,
    &-bar {
      height: 0.1rem;
      left: 0;
      position: absolute;
      top: 2.25rem;
    }
    &-basebar {
      background-color: $dark-blue;
      width: 100%;
      z-index: 0;
    }
    &-bar {
      background-color: $light-blue;
      width: calc(var(--step) * 25%);
      z-index: 1;
    }
    &-item {
      align-items: center;
      color: $dark-blue;
      display: flex;
      flex-direction: column;
      justify-content: center;
      transition-delay: 0;
      &--icon {
        align-items: center;
        background-color: $white;
        border: 0.15rem solid $dark-blue;
        border-radius: 50%;
        display: inline-flex;
        font-size: 1.2rem;
        height: 4.5rem;
        justify-content: center;
        position: relative;
        width: 4.5rem;
        z-index: 2;
      }
      &--title {
        font-size: 0.75rem;
      }
      &.active {
        color: $light-blue;
        .signup__nav-item--icon {
          border: 0.15rem solid $light-blue;
        }
      }
    }
  }
  &__content {
    margin-top: 3rem;
    height: var(--height);
    position: relative;
    width: 100%;
    &-item {
      display: none;
      left: 0;
      padding: 0 20%;
      position: absolute;
      top: 0;
      width: 100%;
      &.active {
        display: block;
      }
      form {
        .type {
          display: flex;
          height: 2rem;
          margin-bottom: .5rem;
          &-select {
            align-items: center;
            color: $input-color;
            cursor: pointer !important;
            display: flex;
            font-size: 0.8rem;
            label {
              cursor: pointer !important;
              line-height: 0.8rem;
            }
            &:first-child {
              margin-right: 1.5rem;
            }
            input {
              cursor: pointer !important;
              margin: -1px 0.35rem 0 0;
              vertical-align: middle;
              width: auto;
            }
          }
        }
        input {
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 0.25rem;
          color: $input-color;
          font-size: 0.75rem;
          margin-bottom: 0.75rem;
          outline: none;
          padding: 0.5rem 0.8rem;
          width: 100%;
        }
        input[type="number"] {
          appearance: textfield;
          &::-webkit-inner-spin-button,
          &::-webkit-outer-spin-button {
            appearance: none;
          }
        }
      }
      .next,
      .finish-btn {
        background-color: $light-blue;
        border-radius: 0.25rem;
        color: $white;
        display: inline-block;
        text-align: center;
        width: 100%;
        &:hover {
          background-color: $hover-light-blue;
          span {
            cursor: pointer !important;
          }
        }
      }
      .next {
        font-size: 0.8rem;
        padding: 0.5rem 0;
      }
      .finish-btn {
        font-size: 1rem;
        padding: 0.8rem 0;
      }
    }
  }
}
@media (max-width: 768px) {
  .signup {
    &__nav {
      &-bar,
      &-basebar {
        top: 1.75rem;
      }
      &-item--icon {
        width: 3.5rem;
        height: 3.5rem;
      }
    }
    &__content-item {
      padding: 0;
    }
  }
}
@media (max-width: 480px) {
  .signup {
    margin-top: 2.5rem;
    &__nav {
      &-bar,
      &-basebar {
        top: 1.5rem;
      }
      &-item {
        &--icon {
          font-size: 0.8rem;
          height: 2rem;
          width: 2rem;
        }
        &--title {
          font-size: 0;
        }
        &.great {
          .signup__nav {
            &-item {
              &--icon {
                font-size: 1.2rem;
                height: 3rem;
                width: 3rem;
              }
              &--title {
                font-size: 0.75rem;
              }
            }
          }
        }
      }
    }
    &__content {
      margin-top: 1rem;
    }
  }
}
</style>
<script>
export default {
  data() {
    return {
      step: 1,
      itemHeight: 0,
      type: "cpf",
    };
  },
  methods: {
    changeHeight() {
      switch (this.step) {
        case 1:
          this.itemHeight = this.$refs.item1.clientHeight;
          break;
        case 2:
          this.itemHeight = this.$refs.item2.clientHeight;
          break;
        case 3:
          this.itemHeight = this.$refs.item3.clientHeight;
          break;
        case 4:
          this.itemHeight = this.$refs.item4.clientHeight;
          break;
        case 5:
          this.itemHeight = this.$refs.item5.clientHeight;
          break;
        default:
          break;
      }
    },
    nextStep() {
      if (this.step != 5) {
        this.step += 1;
        this.changeHeight();
      }
    },
  },
  mounted: function () {
    this.changeHeight();
  },
};
</script>