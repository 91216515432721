<template>
  <div class="col-sm-12">
    <div class="row form-group">
      <label for="tracking_code" class="col-12 text-left no-p-l"
        >Cadastro de Rastreio</label
      >
      <div class="col-sm-10 col-11 no-p-l no-p-r">
        <input
          type="text"
          name="tracking_code"
          class="form-control alter-border"
          v-model="tracking"
          id="tracking_code"
          :disabled="block == true"
          :class="{ DisableOn: block }"
        />
      </div>
      <div class="col-1 no-p-l">
        <button
          type="button"
          class="btn btn-flat"
          v-on:click="verify()"
          :class="{
            'btn-info': !editing || editing == 'new',
            'btn-success': editing,
          }"
        >
          <i
            data-toggle="tooltip"
            :class="{
              'fa fa-plus': editing == 'new',
              'fa fa-pencil': !editing,
              'fa fa-check': editing,
            }"
          ></i>
        </button>
      </div>
    </div>
    <!--     <fieldset style="text-align: center;">
      <legend>Código de Rastreio</legend>
      <span v-if="tracking_msg" style="font-size: 14px;" class="badge badge-success">{{ tracking_msg }}   <i v-on:click="edit()" class="options fa fa-pencil"></i> <i v-on:click="reset()" class="options fa fa-times"></i></span>
    </fieldset> -->
  </div>
</template>
 <style lang="scss">
legend {
  font-size: 16px;
}

.options:hover {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.514);
  transition: 0.5s;
}
</style>
<script>
import axios from "axios";
import UICartStatus from "./UICartStatus.vue";
export default {
  components: { UICartStatus },
  props: ["cart", "tracker", "client_atm", "order_atm", "user_name"],
  data() {
    return {
      edit_button: false,
      alter_selected: "",
      tracking: "",
      tracking_msg: "",
      block: false,
      editing: false,
      tracking_api: {
        tracking_code: "",
        id: this.cart,
        cart_step_id: "",
        message: "",
        client_id: this.client_atm,
        order_id: this.order_atm,
      },
    };
  },
  mounted() {
    this.tracking_api.cart_step_id =
      document.getElementById("select_status").value;
  },

  created() {
    if (this.tracker) {
      this.tracking = this.tracker;
      this.tracking_msg = this.tracker;
      this.block = true;
    } else {
      this.editing = "new";
      this.tracking_msg = "";
    }
  },

  methods: {
    verify() {
      if (this.block == true) {
        this.block = false;
        this.editing = true;
      } else {
        this.editing = false;
        this.add();
      }
    },

    add() {
      if (this.tracking.length == 0) {
        this.editing = "new";
        this.tracking_msg = "";
        this.tracking_api.message =
          "Usuário " +
          "<strong>" +
          this.user_name +
          "</strong>" +
          " Removeu o Código de Rastreio";
        this.SendAPI();
      } else {
        this.tracking_api.cart_step_id =
          document.getElementById("select_status").value;
        this.tracking_msg = this.tracking;
        this.block = true;
        this.tracking_api.message =
          "Usuário " +
          "<strong>" +
          this.user_name +
          "</strong>" +
          " Alterou o Código de Rastreio para " +
          "<strong>" +
          this.tracking_msg +
          "</strong>";
        if (this.tracking_api.cart_step_id == 3) {
          this.tracking_api.cart_step_id = document.getElementById(
            "select_status"
          ).value = 4;
          this.tracking_api.message =
            "Usuário " +
            "<strong>" +
            this.user_name +
            "</strong>" +
            " Alterou o Código de Rastreio para " +
            "<strong>" +
            this.tracking_msg +
            "</strong>" +
            ", Ajuste do status para " +
            "<strong>" +
            "Produto Enviado" +
            "</strong>";
        }
        this.SendAPI();
      }
    },
    SendAPI() {
      this.tracking_api.tracking_code = this.tracking_msg;
      this.tracking_api.cart_step_id =
        document.getElementById("select_status").value;
      axios
        .post("/api/cart", this.tracking_api)
        .then((response) => {})
        .catch(function (error) {
          console.log(error.response);
        });
    },
    edit() {
      this.block = false;
      this.editing = true;
      this.tracking_msg = "";
    },
    reset() {
      this.tracking = "";
      this.tracking_msg = "";
      this.block = false;
      this.editing = false;
      this.deleted = false;
      this.SendAPI();
    },
  },
};
</script> 